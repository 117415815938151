(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/marketplace-brand/assets/marketplace-brand-context.js') >= 0) return;  svs.modules.push('/components/marketplace/marketplace-brand/assets/marketplace-brand-context.js');
"use strict";


const {
  createContext,
  useMemo
} = React;
const {
  getProductDisplayName
} = svs.components.lbUtils.brandMapping.fn;
const {
  getRacingDisplayName
} = svs.utils.racing;
const MarketplaceBrandContext = createContext();
const MarketplaceBrandProvider = _ref => {
  let {
    children
  } = _ref;
  const value = useMemo(() => ({
    getBrandedDisplayName: getProductDisplayName,
    getRacingBrandedDisplayName: getRacingDisplayName
  }), []);
  return React.createElement(MarketplaceBrandContext.Provider, {
    value: value
  }, children);
};
setGlobal('svs.components.marketplace.marketplaceBrand.MarketplaceBrandContext', MarketplaceBrandContext);
setGlobal('svs.components.marketplace.marketplaceBrand.MarketplaceBrandProvider', MarketplaceBrandProvider);

 })(window);